// Import Javascript Modules
import { CookieExtensions } from "./Modules/CookieExtensions";
import { LocalStorageExtensions } from "./Modules/LocalStorageExtensions";
import { ConnectionExtensions } from "./Modules/ConnectionExtensions";
import { DropdownExtensions } from "./Modules/BootstrapExtensions";
import { PopoverExtensions } from "./Modules/BootstrapExtensions";
import { MovableModalExtensions } from "./Modules/MovableModalExtensions";
import { JavascriptHandler } from "./Modules/JavascriptHandler";
import { CssLinkHandler } from "./Modules/CssLinkHandler";
import { BrowserResizeExtensions } from "./Modules/BrowserResizeExtensions";
import { CssClassInjector } from "./Modules/CssClassInjector";
import { ServiceWorkerUpdate } from "./Modules/ServiceWorkerUpdate";
import { EventListenerExtensions } from "./Modules/EventListenerExtensions";
import { ResizeObserverExtensions } from "./Modules/ResizeObserverExtensions";
import { NavigationExtensions } from "./Modules/NavigationExtensions";

// Import Stylesheets (SCSS & CSS)
import "../Styles/Simpleter.Components.scss";
import { right } from "@popperjs/core";
import imageCompression from 'browser-image-compression/dist/browser-image-compression.js';

class SimpleterJSExtensions {
    constructor() {
        this.Popover = new PopoverExtensions();
        this.BrowserResize = new BrowserResizeExtensions();
        this.Dropdown = new DropdownExtensions();
        this.MovableModal = new MovableModalExtensions();
        this.CssClassInjector = new CssClassInjector();
        this.JavascriptHandler = new JavascriptHandler();
        this.CssLinkHandler = new CssLinkHandler();
        this.Cookies = new CookieExtensions();
        this.LocalStorage = new LocalStorageExtensions();
        this.Connection = new ConnectionExtensions();
        this.ServiceWorkerUpdate = new ServiceWorkerUpdate();
        this.EventListener = new EventListenerExtensions();
        this.ResizeObserver = new ResizeObserverExtensions();
        this.Navigation = new NavigationExtensions();
    }

    BrowserResize: BrowserResizeExtensions;
    Popover: PopoverExtensions;
    Dropdown: DropdownExtensions;
    MovableModal: MovableModalExtensions;
    CssClassInjector: CssClassInjector;
    JavascriptHandler: JavascriptHandler;
    CssLinkHandler: CssLinkHandler;
    Cookies: CookieExtensions;
    LocalStorage: LocalStorageExtensions;
    Connection: ConnectionExtensions;
    ServiceWorkerUpdate: ServiceWorkerUpdate;
    EventListener: EventListenerExtensions;
    ResizeObserver: ResizeObserverExtensions;
    Navigation: NavigationExtensions;

    Compress(image: File): Promise<File> {

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            libURL: 'browser-image-compression/dist/browser-image-compression.js'
        }

        return imageCompression(image, options)
    }

    CopyText(alertText: string, textToCopy: string) {
        if (textToCopy != undefined) {
            navigator.clipboard.writeText(textToCopy)
                .then(
                    function () {
                        alert(alertText);
                    })
                .catch(function (error) {
                    alert(error);
                });
        }
    }

    SetFocus(componentId: string, delay: number) {
        setTimeout(() => {
            var element = document.querySelector(componentId);
            if (element != undefined) {
                (element as HTMLElement).focus();
            }
        }, delay);
    }

    AlignWidth(mainId: string, secondaryId: string) {
        var dropdownMenu = document.querySelector<HTMLElement>(secondaryId);
        var dropdownToggle = document.querySelector<HTMLElement>(mainId);

        if (dropdownMenu != undefined && dropdownToggle != undefined) {
            var size = window.getComputedStyle(dropdownToggle);
            dropdownMenu.style.width = size.width;
        }
    }

    AlignMaxWidth(mainId: string, secondaryId: string) {
        var componentToAlign = document.querySelector<HTMLElement>(secondaryId);
        var referenceComponent = document.querySelector<HTMLElement>(mainId);

        if (componentToAlign != undefined && referenceComponent != undefined) {
            var size = window.getComputedStyle(referenceComponent);
            componentToAlign.style.maxWidth = size.width;
        }
    }

    SetComponentStyleAttribute(componentId: string, attributeName: string, attributeValue) {
        var element = document.querySelector<HTMLElement>(componentId);
        var existingAttributeValue = null;
        if (element != undefined) {
            existingAttributeValue = element.style[attributeName]
            element.style[attributeName] = attributeValue;
        }

        return existingAttributeValue;
    }

    RemoveElementFromDom(selectorString: string) {
        const elementsToRemove = document.querySelectorAll(selectorString);
        elementsToRemove.forEach((element) => element.remove());
    }

    ModalUnsubscribe() {
        var element = document.body;
        element.classList.remove("modal-open");
        element.style.overflow = null;
        element.style.padding = null;
    }

    SetComponentMaxHeightToAvailableHeight(componentId: string, bottomMargin: number = 0, forceHeight: boolean = false) {
        var element = document.querySelector<HTMLElement>(componentId);
        if (element != undefined) {
            var offsetTop = element.getBoundingClientRect().y;

            if (offsetTop > window.innerHeight) {
                element.style.maxHeight = "500px";
            } else {
                var calculatedHeight = (window.innerHeight - offsetTop - bottomMargin);

                if (calculatedHeight < 120)
                    calculatedHeight = 120;

                element.style.maxHeight = calculatedHeight + "px";

                if (forceHeight) {
                    element.style.height = element.style.maxHeight;
                }
            }
        }
    }

    IsMobileDevice(): boolean {
        var agent = navigator.userAgent.toLowerCase();
        var mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/;

        var result = mobile.test(agent);
        return result;
    }

    IsOnline(): boolean {
        return navigator.onLine;
    }

    GetConnectionType(): string {
        if ('connection' in navigator) {
            var connection = navigator['connection'];
            var connectionType = connection['effectiveType'];

            if (connectionType == undefined)
                return null;
            else
                return connectionType;
        }
        return null;
    }

    Alert(text: string) {
        alert(text);
    }

    Confirm(text: string): boolean {
        return confirm(text);
    }

    IsOverflown(id: string): boolean {
        var element = document.querySelector(id);
        if (element != undefined) {
            return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        }
        else {
            return false;
        }
    }

    GetCssVariableValue(variableName: string): string {
        const value = getComputedStyle(document.documentElement)
            .getPropertyValue(variableName);
        return value.trim();
    }

    GetWindowHeight(): number {
        return window.innerHeight;
    }

    GetElementRect(selector: string): any {
        const element = document.querySelector(selector);

        if (element != undefined) {
            const rect = element.getBoundingClientRect();

            var position = {
                Top: Math.floor(rect.top),
                Left: Math.floor(rect.left),
                Bottom: Math.floor(rect.bottom),
                Right: Math.floor(rect.right),
                Height: Math.floor(rect.height),
                Width: Math.floor(rect.width)
            }

            return position;
        }
        else {
            return null;
        }
    }

    GetWindowWidth(): number {
        return window.innerWidth;
    }

    private async FetchWithAuthentication(url: string, authToken: string) {
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${authToken}`);
        //Permet de laisser passer les req par ngrok pour publier son localhost sur internet
        //Pas d'impact en prod a part un header en plus da les requètes
        //https://ngrok.com/abuse
        //Todo : à enlever quand le dev hors ligne sera finalisé
        headers.set('ngrok-skip-browser-warning', '1');
        return fetch(url, { headers });
    }

    async DisplayProtectedImage(imageId: string, imageUrl: string, authToken: string) {
        const imageElement = document.getElementById(imageId) as HTMLImageElement;
        if (imageElement != null) {
            const response = await this.FetchWithAuthentication(imageUrl, authToken);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            imageElement.src = objectUrl;
            imageElement.onload = () => URL.revokeObjectURL(objectUrl);
        }
    }

    private async openCacheStorage() {
        return await window.caches.open("SimpleterOfflineStorage");
    }

    async PutDocumentInCache(documentServerAddress: string, documentId: string, authToken: string, cacheUrlPrefix: string) {
        const cacheUrl = cacheUrlPrefix + documentId;
        const documentUrl = documentServerAddress + "document/" + documentId;
        const response = await this.FetchWithAuthentication(documentUrl, authToken);
        const cache = await this.openCacheStorage();
        const requestInfo = new Request(cacheUrl);
        cache.put(requestInfo, response);
    }

    async PutJsonInCache(url: string, content: string) {
        const cache = await this.openCacheStorage();
        const blobContent = new Blob([content], {
            type: 'application/json',
        });
        const headers = new Headers();
        headers.append("content-length", blobContent.size.toString())
        const response = new Response(blobContent, {
            headers: headers,
        })
        cache.put(url, response);
    }

    async GetJsonFromCache(url: string) {
        const cache = await this.openCacheStorage();
        var resp = await cache.match(url);
        if ((resp != null) && (resp.ok == true)) {
            var blob = await resp.blob();
            return await blob.text();
        }
        else return null;
    }

    async DisplayImageAndKeepObjectUrl(imageId: string, imageUrl: string, authToken: string) {
        const imageElement = document.getElementById(imageId) as HTMLImageElement;
        if (imageElement != null) {
            const response = await this.FetchWithAuthentication(imageUrl, authToken);
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            imageElement.src = objectUrl;
            return objectUrl;
        }
    }

    async DisplayImageFromCacheAndKeepObjectUrl(imageId: string, cacheUrl: string) {
        const cache = await this.openCacheStorage();
        const imageElement = document.getElementById(imageId) as HTMLImageElement;
        var resp = await cache.match(cacheUrl);
        if ((resp != null) && (resp.ok == true) && (imageElement != null)) {
            var blob = await resp.blob();
            const objectUrl = URL.createObjectURL(blob);
            imageElement.src = objectUrl;
            return objectUrl;
        }
        else return null;
    }

    async RevokeObjectUrl(objectUrl: string) {
        URL.revokeObjectURL(objectUrl);
    }

    DownloadFileFromPost(url: string, authToken: string) {
        var form = document.createElement('form')
        form.method = 'post'
        form.target = '_blank'
        form.action = url
        form.innerHTML = '<input type="hidden" name="jwtToken" value="' + authToken + '">'
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    async DownloadDocumentsServerFile(imageUrl: string, authToken: string, fileName: string) {
        const response = await this.FetchWithAuthentication(imageUrl, authToken);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        const anchorElement = document.createElement('a');
        anchorElement.href = objectUrl;
        anchorElement.download = fileName;
        anchorElement.click();
        anchorElement.remove();
        URL.revokeObjectURL(objectUrl);
    }

    async PostDocumentFromBrowserCache(documentUrl: string, cacheUrl: string, accessToken: string, fileName: string) {
        const cache = await this.openCacheStorage();
        var resp = await cache.match(cacheUrl);
        if ((resp != null) && (resp.ok == true)) {
            var formdata = new FormData();
            var blob = await resp.blob();
            formdata.append("file", blob, fileName);
            const headers = new Headers();
            headers.set('Authorization', `Bearer ${accessToken}`);
            //Permet de laisser passer les req par ngrok pour publier son localhost sur internet
            //Pas d'impact en prod a part un header en plus da les requètes
            //https://ngrok.com/abuse
            //Todo : à enlever quand le dev hors ligne sera finalisé
            headers.set('ngrok-skip-browser-warning', '1');
            var response = await fetch(documentUrl,
                {
                    method: "POST",
                    body: formdata,
                    headers
                });
            var blobContent = await response.blob();
            var responseContentAsString = await blobContent.text();
            return responseContentAsString;
        }
    }

    async DownloadCacheFile(cacheUrl: string, fileName: string) {
        const cache = await this.openCacheStorage();
        var resp = await cache.match(cacheUrl);
        if ((resp != null) && (resp.ok == true)) {
            const anchorElement = document.createElement('a');
            var blob = await resp.blob();
            const objectUrl = URL.createObjectURL(blob);
            anchorElement.href = objectUrl;
            anchorElement.download = fileName;
            anchorElement.click();
            anchorElement.remove();
            URL.revokeObjectURL(objectUrl);
        }
    }

    private blobToBase64(blob) {
        return new Promise<string>((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    async DownloadAllCacheToFile() {
        const cache = await this.openCacheStorage();
        const keys = await cache.keys();
        const cacheContentFile: Array<{ url: string; content: string; }> = [];
        await Promise.all(keys.map(async request => {
            var resp = await cache.match(request);
            var blob = await resp.blob();
            var fileContent = await this.blobToBase64(blob);
            cacheContentFile.push({
                url: request.url.replace(document.location.origin, ''),
                content: fileContent
            });
        }));
        const str = JSON.stringify(cacheContentFile);
        const bytes = new TextEncoder().encode(str);
        const bigblob = new Blob([bytes], {
            type: "application/json;charset=utf-8"
        });
        const anchorElement = document.createElement('a');
        const objectUrl = URL.createObjectURL(bigblob);
        anchorElement.href = objectUrl;
        anchorElement.download = "SimpleterCacheContent.json";
        anchorElement.click();
        anchorElement.remove();
        URL.revokeObjectURL(objectUrl);
    }

    async ClearCache() {
        const cache = await this.openCacheStorage();
        const keys = await cache.keys();
        keys.forEach(request => {
            cache.delete(request);
        });
    }

    private static dataURLtoBlob(dataURL) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    async UploadFileToCache() {
        const cache = await this.openCacheStorage();
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = 'application/json'
        input.style.display = 'none';
        input.addEventListener('change', function (event) {
            var file = (event.target as HTMLInputElement).files[0];
            var reader = new FileReader();
            reader.onload = function () {
                const cacheContentFile: Array<{ url: string; content: string; }> =
                    JSON.parse(reader.result as string);
                cacheContentFile.forEach(async value => {
                    var blobContent = SimpleterJSExtensions.dataURLtoBlob(value.content);
                    const headers = new Headers();
                    headers.append("content-length", blobContent.size.toString())
                    const response = new Response(blobContent, {
                        headers: headers,
                    })
                    cache.put(value.url, response);
                })
            }
            reader.readAsText(file);
        });
        input.click();
        input.remove();
    }

    async DeleteFromCache(cacheUrlPrefix: string) {
        const cache = await this.openCacheStorage();
        const keys = await cache.keys();
        keys.forEach(request => {
            var startsWith = document.location.origin + cacheUrlPrefix;
            if (request.url.startsWith(startsWith)) {
                cache.delete(request);
            }
        });
    }
}

window[SimpleterJSExtensions.name] = new SimpleterJSExtensions();
